<template>
  <div>
    <!---Title-->
    <div class="title">
      <h1>Registro de envíos</h1>
      <div class="toolbar">
        <!---Message--->
        <span v-if="new Date().getHours() < limitHour" class="text-sm bg-secondary text-white py-2 px-4 rounded">Hora límite para envío de pedidos: {{
            limitHour
          }}:00</span>
        <span v-else class="text-sm bg-gray-900 text-white py-2 px-4 rounded">Tus pedidos serán enviados mañana</span>
        <!---End-->
        <button @click="validateList"
                :disabled="total===0"
                class="btn justify-self-end">
          <span>Registrar envíos S/ ({{ total.toFixed(2) }})</span>
        </button>
      </div>
    </div>
    <!---End-->
    <!---Form-->
    <div class="max-h-0 overflow-hidden transition-all duration-1000"
         :class="{'showForm': showForm}">
      <div class="grid grid-cols-12 gap-7 md:grid-cols-6">
        <div class="col-span-6 space-y-4 md:col-span-full">
          <div class="grid grid-cols-2 gap-7 md:grid-cols-1 md:gap-4">
            <!--Name-->
            <div class="form-group">
              <label for="fullName">Nombre de cliente*</label>
              <input v-model="document.fullName" class="input" type="text" id="fullName"
                     name="fullName">
              <span v-if="validationErrors.fullName" class="error">{{ validationErrors.fullName }}</span>
            </div>
            <!--End-->
            <!--Phone-->
            <div class="form-group">
              <label for="phone">Celular*</label>
              <input v-model="document.phone" class="input" type="text" id="phone"
                     name="phone">
              <span v-if="validationErrors.phone" class="error">{{ validationErrors.phone }}</span>
            </div>
            <!--End-->
          </div>
          <!--Pickup address--->
          <div class="form-group">
            <label for="district">Local de recojo*</label>
            <input v-model="document.pickupAddress" class="input" type="text" id="phone"
                   name="phone">
            <span v-if="validationErrors.pickupAddress" class="error">{{ validationErrors.pickupAddress }}</span>
          </div>
          <!--End-->
          <!--Address-->
          <div class="form-group">
            <label for="address">Dirección de entrega*</label>
            <input v-model="document.address" class="input" type="text" id="address"
                   name="address">
            <span v-if="validationErrors.address" class="error">{{ this.validationErrors.address }}</span>
            <span @click="openGoogleMaps" class="text-sm underline cursor-pointer block mt-2">Ver en mapa</span>
          </div>
          <!--End-->
          <!--Reference-->
          <div class="form-group">
            <label for="address">Referencia</label>
            <input v-model="document.reference" class="input" type="text" id="reference"
                   name="reference">
            <span v-if="validationErrors.reference" class="error">{{ this.validationErrors.reference }}</span>
          </div>
          <!--End-->
          <!--District and location-->
          <div class="grid grid-cols-2 gap-7 md:grid-cols-1 md:gap-4">
            <!--District-->
            <div class="form-group form-group--m0">
              <label for="district">Distrito*</label>
              <select v-model="document.district" class="select" name="district" id="district">
                <option v-for="(e, index) in districts" :value="e" :key="index">{{ e.label }}</option>
              </select>
              <span v-if="validationErrors.district" class="error">{{ validationErrors.district }}</span>
            </div>
            <!--End-->
            <!--Location-->
            <div class="form-group">
              <label for="location">Link de Ubicación (Opcional)</label>
              <input v-model="document.location" class="input" type="text" id="location"
                     name="location">
              <span v-if="validationErrors.location" class="error">{{ validationErrors.location }}</span>
            </div>
            <!--End-->
          </div>
          <!--End-->
          <!--Quantity and product-->
          <div class="grid grid-cols-2 gap-7 md:grid-cols-1 md:gap-4">
            <!--Product-->
            <div class="form-group">
              <label for="product">Producto*</label>
              <input v-model="document.product" class="input" type="text" id="product"
                     name="product">
              <span v-if="validationErrors.product" class="error">{{ validationErrors.product }}</span>
            </div>
            <!--End-->
            <!--Quantity-->
            <div class="form-group">
              <label for="quantity">Cantidad*</label>
              <input v-model.number="document.quantity" class="input" type="number" id="quantity"
                     name="quantity">
              <span v-if="validationErrors.quantity" class="error">{{ validationErrors.quantity }}</span>
            </div>
            <!--End-->
          </div>
          <!--End-->
          <!--Total to be collected and notes-->
          <div class="grid grid-cols-2 gap-7 md:grid-cols-1 md:gap-4">
            <!---Total to be collected-->
            <div class="form-group">
              <label for="totalToBeCollected">Total a cobrar*</label>
              <input v-model.number="document.totalToBeCollected" class="input" type="number" id="totalToBeCollected"
                     name="totalToBeCollected">
              <span v-if="validationErrors.totalToBeCollected" class="error">{{
                  this.validationErrors.totalToBeCollected
                }}</span>
            </div>
            <!--End-->
            <!--Notes-->
            <div class="form-group">
              <label for="notes">Notas de entrega</label>
              <input v-model="document.notes" class="input" type="text" id="notes" name="notes">
              <span v-if="validationErrors.notes" class="error">{{ validationErrors.notes }}</span>
            </div>
            <!--End-->
          </div>
          <!--End-->
          <!---Type of service-->
          <div class="flex justify-between gap-7 md:grid-cols-1 md:gap-4">
            <div class="w-full">
            <span
              @click="document.type = {  value: 'scheduled',price: 0, label: 'Programado'}"
              class="border border-solid border-gray-300 block py-3 px-2 text-center text-sm rounded transition-all duration-300 cursor-pointer mb-2"
              :class="{'bg-secondary text-white' : document.type.value === 'scheduled'}">PROGRAMADO</span>
              <a
                class="text-sm block underline"
                href="https://www.google.com/maps/d/u/0/viewer?mid=1B5s_QE6GWFAwHytl_34BhX1iJnR7ZKM&ll=-12.030392323041076%2C-76.98212009999999&z=11"
                target="_blank">Ver mapa de cobertura</a>
            </div>
            <div class="w-full">
            <span
              @click="document.type = {  value: 'flex',price: 2.10, label: 'Flex'}"
              class="border border-solid border-gray-300 block py-3 px-2 text-center text-sm rounded transition-all duration-300 cursor-pointer mb-2"
              :class="{'bg-secondary text-white' : document.type.value === 'flex'}">FLEX</span>
              <a
                class="text-sm block underline"
                href="https://www.google.com/maps/d/u/0/viewer?mid=1B5s_QE6GWFAwHytl_34BhX1iJnR7ZKM&ll=-12.030392323041076%2C-76.98212009999999&z=11"
                target="_blank">Ver mapa de cobertura</a>
            </div>
          </div>
          <!--End-->
        </div>
        <div class="col-span-6 relative">
          <div class="grid grid-cols-2 gap-7 md:grid-cols-1 md:gap-4 mb-4">
            <!--Pickup date-->
            <div class="form-group w-full">
              <label for="deadline">Fecha de recojo*</label>
              <v-date-picker
                expanded
                :min-date="minDate"
                id="pickUpDate"
                name="pickUpDate"
                v-model="document.pickUpDate"
                color="red"
                :timezone="`America/Lima`"
              />
              <span v-if="this.validationErrors.pickUpDate" class="error">{{ this.validationErrors.pickUpDate }}</span>
            </div>
            <!--End-->
            <!--Deadline-->
            <div class="form-group w-full">
              <label for="deadline">Fecha de entrega*</label>
              <v-date-picker
                expanded
                :min-date="minDate"
                id="deadline"
                name="deadline"
                v-model="document.deadline"
                color="red"
                :timezone="`America/Lima`"
              />
              <span v-if="validationErrors.deadline" class="error">{{ validationErrors.deadline }}</span>
            </div>
            <!--End-->
          </div>
          <!--Send-->
          <div class="flex justify-between items-center col-span-2 bg-white p-4 rounded">
            <div>
              <span class="text-sm block mb-2">COSTO DE ENVÍO</span>
              <span class="text-2xl font-semibold block ">S/ {{ document.district?.price + document.type?.price }}</span>
            </div>
            <button @click="addToList" class="btn justify-self-end">
              <span>Agregar</span>
            </button>
          </div>
          <!--End-->
        </div>
      </div>
    </div>
    <!---End-->
    <hr class="w-full  bg-gray-900 mt-12 mb-6">
    <!--Table toolbar-->
    <table-toolbar
      :district="districts[0]"
      @done="importDone"
      :show-form="showForm"
      @toggleForm="showForm = !showForm"
      @triggerSearch="triggerSearch"></table-toolbar>
    <!--End-->
    <!--Table-->
    <div class="max-w-full overflow-auto">
      <!--Header-->
      <div class="table w-full">
        <div class="table__header">
          <div><span>Fecha de recojo</span></div>
          <div><span>Fecha de entrega</span></div>
          <div><span>Tipo de servicio</span></div>
          <div><span>Local de recojo</span></div>
          <div><span>Dirección</span></div>
          <div><span>Referencia</span></div>
          <div><span>Distrito</span></div>
          <div><span>Nombre de cliente</span></div>
          <div><span>Teléfono</span></div>
          <div><span>Costo de envío</span></div>
          <div><span>Producto</span></div>
          <div><span>Cantidad</span></div>
          <div><span>Total a cobrar</span></div>
          <div><span>Notas de entrega</span></div>
          <div><span>Ubicación</span></div>
          <div><span>Eliminar</span></div>
        </div>
        <div class="table__content">
          <div v-for="(o, index) in filteredList"
               :key="index"
               class="row md:!bg-white"
               :class="{'row--error' : o.error}">
            <div class="row__item">
              <span class="name">FECHA DE RECOJO</span>
              <input v-model="o.pickUpDate" class="input" type="date" id="pickUpDate" name="pickUpDate">
            </div>
            <div class="row__item">
              <span class="name">FECHA DE ENTREGA</span>
              <input v-model="o.deadline" class="input" type="date" id="deadline" name="deadline">
            </div>
            <div class="row__item">
              <span class="name">TIPO DE SERVICIO</span>
              <select v-model="o.type" class="select" name="type" id="type">
                <option
                  v-for="(e, index) in [  { value: 'scheduled', price: 0,label: 'Programado'} ,  { value: 'flex', price: 2.10,label: 'Flex'}]"
                  :value="e" :key="index">{{ e.label }}
                </option>
              </select>
            </div>
            <div class="row__item">
              <span class="name">LOCAL DE RECOJO</span>
              <input v-model="o.pickupAddress" class="input" type="text" id="address" name="address">
            </div>
            <div class="row__item">
              <span class="name">DIRECCIÓN</span>
              <input v-model="o.address" class="input" type="text" id="address" name="address">
            </div>
            <div class="row__item">
              <span class="name">REFERENCIA</span>
              <input v-model="o.reference" class="input" type="text" id="reference" name="reference">
            </div>
            <div class="row__item">
              <span class="name">DISTRITO</span>
              <select v-model="o.district" class="select" name="district" id="district">
                <option v-for="(e, index) in districts" :value="e" :key="index">{{ e.label }}</option>
              </select>
            </div>
            <div class="row__item">
              <span class="name">CLIENTE</span>
              <input v-model="o.fullName" class="input" type="text" id="fullName" name="fullName">
            </div>
            <div class="row__item">
              <span class="name">CELULAR</span>
              <input v-model="o.phone" class="input" type="text" id="phone" name="phone">
            </div>
            <div class="row__item">
              <span class="name">COSTO DE ENVÍO</span>
              <input :value="o.district?.price + o.type?.price" class="input" type="text" disabled id="price"
                     name="price">
            </div>
            <div class="row__item">
              <span class="name">PRODUCTO</span>
              <input v-model="o.product" class="input" type="text" id="product" name="product">
            </div>
            <div class="row__item">
              <span class="name">CANTIDAD</span>
              <input v-model="o.quantity" class="input" type="number" id="quantity" name="quantity">
            </div>
            <div class="row__item">
              <span class="name">TOTAL A COBRAR</span>
              <input v-model="o.totalToBeCollected" class="input" type="number" id="totalToBeCollected"
                     name="totalToBeCollected">
            </div>
            <div class="row__item">
              <span class="name">NOTAS DE ENTREGA</span>
              <input v-model="o.notes" class="input" type="text" id="notes"
                     name="notes">
            </div>
            <div class="row__item">
              <span class="name">UBICACIÓN</span>
              <input v-model="o.location" class="input" type="text" id="location"
                     name="location">
            </div>
            <div>
              <span @click="deleteToList(index)">
                <trash-icon class="h-6"></trash-icon>
              </span>
            </div>
          </div>
        </div>
      </div>
      <!--End-->
    </div>
    <!---End--->
    <!--Register payment and save in firestore-->
    <pay
      @close="showPaySidebar = false"
      :data="sidebarData"
      :list="list"
      @done="done"
      :class="{'show' : showPaySidebar}"
      :show-navbar="showPaySidebar"></pay>
    <!---End--->
  </div>
</template>

<script>
import * as yup from 'yup'
import TrashIcon from '../components/atoms/icons/TrashIcon.vue'
import TableToolbar from '../components/molecules/TableToolbar.vue'
import moment from 'moment'
import Pay from '../components/organisms/Pay.vue'

import { db } from '@/firebase/firebaseConfig'

yup.setLocale({
  mixed: {
    required: 'Este campo es obligatorio'
  },
  number: {
    positive: 'Debe ser positivo',
    integer: 'Debe ser entero'
  },
  string: {
    email: 'Debe ser  un email'
  }
})

export default {
  name: 'Home',
  components: {
    Pay,
    TableToolbar,
    TrashIcon
  },
  data () {
    return {
      limitHour: 8,
      document: {
        quantity: 1,
        totalToBeCollected: 0,
        district: {
          label: 'PUEBLO LIBRE',
          price: 7.9
        },
        type: {
          value: 'scheduled',
          price: 0,
          label: 'Programado'
        }
      },
      search: '',
      list: [],
      showPaySidebar: false,
      showForm: true,
      sidebarData: {},
      validationErrors: {},
      districts: [
        {
          label: 'PUEBLO LIBRE',
          price: 7.9
        },
        {
          label: 'EL AGUSTINO',
          price: 7.9
        },
        {
          label: 'LA VICTORIA',
          price: 7.9
        },
        {
          label: 'CERCADO DE LIMA',
          price: 7.9
        },
        {
          label: 'RIMAC',
          price: 7.9
        },
        {
          label: 'BREÑA',
          price: 7.9
        },
        {
          label: 'MAGDALENA',
          price: 7.9
        },
        {
          label: 'SAN MIGUEL',
          price: 7.9
        },
        {
          label: 'SAN LUIS',
          price: 7.9
        },
        {
          label: 'SAN ISIDRO',
          price: 7.9
        },
        {
          label: 'SAN BORJA',
          price: 7.9
        },
        {
          label: 'MIRAFLORES',
          price: 7.9
        },
        {
          label: 'JESUS MARIA',
          price: 7.9
        },
        {
          label: 'LA MOLINA',
          price: 7.9
        },
        {
          label: 'SANTA ANITA',
          price: 7.9
        },
        {
          label: 'SMP',
          price: 7.9
        },
        {
          label: 'SAN MARTIN DE PORRES',
          price: 7.9
        },
        {
          label: 'INDEPENDENCIA',
          price: 7.9
        },
        {
          label: 'LOS OLIVOS',
          price: 7.9
        },
        {
          label: 'COMAS',
          price: 7.9
        },
        {
          label: 'CALLAO',
          price: 7.9
        },
        {
          label: 'SANTIAGO DE SURCO',
          price: 7.9
        },
        {
          label: 'SURCO',
          price: 7.9
        },
        {
          label: 'SURQUILLO',
          price: 7.9
        },
        {
          label: 'BARRANCO',
          price: 7.9
        },
        {
          label: 'CHORRILLOS',
          price: 7.9
        },
        {
          label: 'SAN JUAN DE MIRAFLORES',
          price: 7.9
        },
        {
          label: 'SJM',
          price: 7.9
        },
        {
          label: 'SAN JUAN DE LURIGANCHO',
          price: 7.9
        },
        {
          label: 'SJL',
          price: 7.9
        },
        {
          label: 'ATE',
          price: 7.9
        },
        {
          label: 'CHORRILLOS 2',
          price: 11.9
        },
        {
          label: 'VILLA EL SALVADOR',
          price: 11.9
        },
        {
          label: 'VES',
          price: 11.9
        },
        {
          label: 'VILLA MARIA DEL TRIUNFO',
          price: 11.9
        },
        {
          label: 'VMT',
          price: 11.9
        },
        {
          label: 'LA MOLINA 2',
          price: 11.9
        },
        {
          label: 'ATE 2',
          price: 11.9
        },
        {
          label: 'HUACHIPA',
          price: 11.9
        },
        {
          label: 'SAN JUAN DE LURIGANCHO 2',
          price: 11.9
        },
        {
          label: 'SJL 2',
          price: 11.9
        },
        {
          label: 'COLLIQUE',
          price: 11.9
        },
        {
          label: 'CARABAYLLO',
          price: 11.9
        },
        {
          label: 'PUENTE PIEDRA',
          price: 11.9
        },
        {
          label: 'SAN MARTIN DE PORRES 2',
          price: 11.9
        },
        {
          label: 'SMP 2',
          price: 11.9
        },
        {
          label: 'CALLAO 2',
          price: 11.9
        },
        {
          label: 'LA PUNTA',
          price: 11.9
        },
        {
          label: 'LA MOLINA 3',
          price: 14.9
        },
        {
          label: 'LURIGANCHO CHOSICA',
          price: 14.9
        },
        {
          label: 'HUAYCAN',
          price: 14.9
        },
        {
          label: 'JICAMARCA',
          price: 14.9
        },
        {
          label: 'CARABAYLLO 2',
          price: 14.9
        },
        {
          label: 'PUENTE PIEDRA 2',
          price: 14.9
        },
        {
          label: 'VENTANILLA',
          price: 14.9
        },
        {
          label: 'VILLA EL SALVADOR 2',
          price: 17.9
        },
        {
          label: 'VILLA MARIA DEL TRIUNFO 2',
          price: 17.9
        },
        {
          label: 'VENTANILLA 2',
          price: 19.9
        },
        {
          label: 'MI PERU',
          price: 19.9
        },
        {
          label: 'PUENTE PIEDRA 3',
          price: 19.9
        },
        {
          label: 'MARQUEZ',
          price: 19.9
        },
        {
          label: 'HUAYCAN 2',
          price: 19.9
        },
        {
          label: 'VENTANILLA 3',
          price: 24.9
        },
        {
          label: 'PACHACUTEC',
          price: 24.9
        },
        {
          label: 'PUENTE PIEDRA 4',
          price: 24.9
        },
        {
          label: 'CARABAYLLO 3',
          price: 24.9
        },
        {
          label: 'LURIGANCHO CHOSICA 2',
          price: 24.9
        },
        {
          label: 'CHACLACAYO',
          price: 24.9
        }
      ],
      schema: {},
      client: {},
      content: {}
    }
  },
  async created () {
    try {
      this.$store.dispatch('setUser') // Set in store

      // Get the current date and time
      const now = new Date()
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())

      // Set the cutoff time for today at the exact desired hour
      const todayAtLimitHour = new Date(today)
      todayAtLimitHour.setHours(this.limitHour, 0, 0, 0)

      // Create yup schema
      this.schema = yup.object().shape({
        fullName: yup.string().required().label('Nombre completo'),
        phone: yup.string().required().label('Celular'),
        address: yup.string().required().label('Dirección'),
        pickupAddress: yup.string().required().label('Local de recojo'),
        reference: yup.string().label('Referencia'),
        district: yup.object().shape({ label: yup.string().required() }).required().label('Distrito'),
        location: yup.string().label('Ubicación'),
        product: yup.string().required().label('Producto'),
        notes: yup.string().label('Notas'),
        quantity: yup.number().required().positive().integer().label('Cantidad'),
        totalToBeCollected: yup.number().label('Total a recolectar'),
        pickUpDate: yup.date()
          .required()
          .test('is-valid-date', 'Fecha inválida', (value) => {
            const currentDate = new Date()
            const selectedDate = new Date(value)

            // If the current time is before 8 PM, allow today or future dates
            if (currentDate < todayAtLimitHour) {
              return selectedDate >= today
            } else {
              // If the current time is after 8 PM, allow only future dates
              const tomorrow = new Date(today)
              tomorrow.setDate(tomorrow.getDate() + 1)
              return selectedDate >= tomorrow
            }
          }).label('Fecha de recojo'),
        deadline: yup.date()
          .required()
          .test('is-valid-date', 'Fecha inválida', (value) => {
            const currentDate = new Date()
            const selectedDate = new Date(value)

            // If the current time is before 8 PM, allow today or future dates
            if (currentDate < todayAtLimitHour) {
              return selectedDate >= today
            } else {
              // If the current time is after 8 PM, allow only future dates
              const tomorrow = new Date(today)
              tomorrow.setDate(tomorrow.getDate() + 1)
              return selectedDate >= tomorrow
            }
          }).label('Fecha de entrega')
      })
      // End

      this.$store.dispatch('showProgress')
      const storedData = localStorage.getItem('orders')
      if (storedData) {
        this.list = JSON.parse(storedData)
      }
      this.document.pickUpDate = new Date().getHours() < this.limitHour ? new Date() : new Date(new Date().setDate(new Date().getDate() + 1))
      this.document.deadline = new Date().getHours() < this.limitHour ? new Date() : new Date(new Date().setDate(new Date().getDate() + 1))
    } catch (e) {
      this.$toast.error('Error al cargar datos', {
        duration: 6000
      })
    } finally {
      this.$store.dispatch('hideProgress')
    }
  },
  computed: {
    total () {
      // eslint-disable-next-line no-return-assign
      return this.list.reduce((acc, o) => { return acc += o.district?.price + o.type?.price }, 0)
    },
    filteredList () {
      return this.list.filter(o => {
        return o.fullName.toLowerCase().includes(this.search.toLowerCase())
      })
    },
    user () {
      return this.$store.state.user
    },
    minDate () {
      return new Date().getHours() < this.limitHour ? new Date() : new Date(new Date().setDate(new Date().getDate() + 1))
    }
  },
  watch: {
    list: {
      handler (val) {
        // Save to local storage
        window.localStorage.setItem('orders', JSON.stringify(this.list))
        // End
      },
      deep: true
    },
    async user (val) {
      if (val) {
        try {
          await this.getClient()
          await this.getContent()
        } catch (e) {
          this.$toast.error('Error al cargar usuario', {
            duration: 6000
          })
        }
      }
    }
  },
  methods: {
    /**
     * Add to list
     * @returns {Promise<void>}
     */
    async addToList () {
      try {
        this.search = ''
        this.validationErrors = {}
        await this.schema.validate({ ...this.document }, { abortEarly: false })
        // Add
        this.document.pickUpDate = moment(this.document.pickUpDate).format('YYYY-MM-DD')
        this.document.deadline = moment(this.document.deadline).format('YYYY-MM-DD')
        this.list.unshift({
          ...this.document,
          error: false
        })
        // Save to local storage
        window.localStorage.setItem('orders', JSON.stringify(this.list))
        // End

        this.reset()

        this.document.pickupAddress = ''
        // End
      } catch (err) {
        if (err.inner) {
          err.inner.forEach(error => {
            if (error.path) {
              this.validationErrors[error.path] = error.message
            }
          })
        } else {
          this.$toast.error('Error al agregar', {
            duration: 6000
          })
        }
        console.log(err.inner)
      }
    },
    /**
     * Delete to list
     * @param index
     */
    deleteToList (index) {
      this.list.splice(index, 1)
      // Save to local storage
      window.localStorage.setItem('orders', JSON.stringify(this.list))
      // End
    },
    /**
     * Open google maps
     */
    openGoogleMaps () {
      const encodedAddress = encodeURIComponent(this.document.address)
      const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`
      window.open(googleMapsUrl, '_blank')
    },
    /**
     * Pre validate shipments
     * @returns {Promise<boolean>}
     */
    async validateList () {
      if (this.total > 0) {
        this.$store.dispatch('showProgress')
        let allItemsValid = true
        for await (const o of this.list) {
          try {
            o.error = false
            await this.schema.validate({ ...o }, { abortEarly: false })
          } catch (err) {
            console.log('Entra')
            allItemsValid = false
            o.error = true
            err.inner.forEach(error => {
              console.log(error.params)
              console.log(error.params?.label)
              if (error.params?.label) {
                this.$toast.error('Error en ' + error.params?.label, {
                  duration: 5000
                })
              }
            })
          }
        }
        this.$store.dispatch('hideProgress')
        if (allItemsValid) {
          this.sidebarData = {
            total: this.total,
            client: this.client,
            content: this.content
          }
          this.showPaySidebar = true
        }
      }
    },
    /**
     * Reset form
     */
    reset () {
      this.document = {
        quantity: 1,
        totalToBeCollected: 0,
        district: {
          label: 'PUEBLO LIBRE',
          price: 7.9
        },
        type: {
          value: 'scheduled',
          price: 0,
          label: 'Programado'
        },
        pickUpDate: new Date(),
        deadline: new Date()
      }
    },
    /**
     * Done
     */
    done () {
      this.reset()
      this.list = []
      this.showPaySidebar = false
      this.$toast.success('Envíos guardados correctamente', {
        duration: 6000
      })
    },
    /**
     * Trigger search
     */
    triggerSearch (value) {
      this.search = value
    },
    /**
     * Get content
     */
    async getContent () {
      const contentDoc = await db.collection('content').doc('XlAI9aWADYktW6JmkttO').get()
      if (contentDoc.exists) {
        this.content = {
          id: contentDoc.id,
          ...contentDoc.data()
        }
      }
    },
    /**
     * Get client
     * @returns {Promise<void>}
     */
    async getClient () {
      const clientDoc = await db.collection('clients').doc(this.user.client.id).get()
      this.client = {
        id: clientDoc.id,
        name: clientDoc.data().businessName,
        hourLimit: clientDoc.data().hourLimit,
        document: clientDoc.data().document,
        paymentMethod: clientDoc.data().paymentMethod
      }
      this.limitHour = this.client.hourLimit
    },
    /**
     * Import done
     */
    importDone (results) {
      console.log('Entra a import')
      results.forEach((r) => {
        if (r['Nombre de cliente']) {
          this.list.push({
            pickUpDate: moment(r['Fecha de recojo'], 'DD_MM_YYYY').format('YYYY-MM-DD'),
            deadline: moment(r['Fecha de entrega'], 'DD_MM_YYYY').format('YYYY-MM-DD'),
            type: r['Tipo de servicio']?.toLowerCase() === 'programado' ? {
              value: 'scheduled',
              price: 0,
              label: 'Programado'
            } : {
              value: 'flex',
              price: 2.10,
              label: 'Flex'
            },
            pickupAddress: r['Local de recojo (Direccion de recojo)']?.trim(),
            address: r['Dirección (Direccion de entrega)']?.trim(),
            reference: r.Referencia?.trim(),
            district: this.districts.find((d) => d.label.toLowerCase() === r.Distrito?.toLowerCase()) || { price: 0 },
            fullName: r['Nombre de cliente'].trim(),
            phone: r['Teléfono'] ? r['Teléfono'] : '',
            product: r.Producto?.trim(),
            quantity: r.Cantidad,
            totalToBeCollected: r['Total a cobrar'],
            notes: r['Notas de entrega'].trim(),
            location: r['Ubicación'].trim()
          })
        }
      })
      this.validateList()
    }
  }
}
</script>

<style>
.showForm {
  max-height: 2000px !important;
}
</style>
